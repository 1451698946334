'use client'
import { useToastStore } from "@/app/stores/toast-store";
import {useCallback, useEffect, useState} from "react";


export default function Toast() {
    const { toast } = useToastStore();

    const [progress, setProgress] = useState(100);

    useEffect(() => {
        // Calculate the interval to decrease the progress bar
        const intervalTime = 100; // update every 100ms
        const totalSteps = (toast?.duration || 1) / intervalTime;
        const decrement = 100 / totalSteps;

        const interval = setInterval(() => {
            setProgress(prev => {
                const newProgress = prev - decrement;
                return newProgress > 0 ? newProgress : 0;
            });
        }, intervalTime);

        // Clear the interval once the duration is reached
        const timeout = setTimeout(() => {
            clearInterval(interval);
            setProgress(100);
        }, toast?.duration);

        // Cleanup the interval and timeout on component unmount
        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [toast?.duration]);

    const toastBoxClasses = useCallback(() => {
        switch (toast?.type) {
            case 'success':
                return 'bg-green-500'
            case 'error':
                return 'bg-red-500'
            default:
                throw new Error('Invalid toast type')
        }
    }, [toast?.type])

    const toastProgressStyle = useCallback(() => {
        switch (toast?.type) {
            case 'success':
                return '#a8f2c4'
            case 'error':
                return '#f2a8a8'
            default:
                throw new Error('Invalid toast type')
        }
    }, [toast?.type])

    return (
        (toast &&
            <div
                className={`z-[51] fixed bottom-5 right-5 w-fit text-white text-md text-center rounded-lg overflow-hidden ${toastBoxClasses()}`}
            >
                <div className="px-6 mt-4">{toast.content}</div>

                <div
                    className={`h-1 mt-2 transition-[width] duration-100`}
                    style={{ width: `${progress}%`, backgroundColor: toastProgressStyle() }}
                />
            </div>
        )
    )
}